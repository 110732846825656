:root {
    --main-bg-color: #F4F6F8; 
    --background-table-color: #FFFFFF;  
    --width-menu: 14rem;
    --margin-left-menu: 14rem;
    --negative-width-menu: -14rem;
    --p-border-subdued: #dfe3e8;
    --font-size: 16px;
    --padding-table: .8rem;
    --p-border: #c4cdd5;
    --p-border-radius-base:3px;
    --avatar-color: #47C1BF;
    --send-ok-color: #47C1BF;
    --p-text: #212b36;
    --width-menu-closed: 0rem;
    --color-active: #3FB9CB;
    --padding: 1rem;
    --p-border:#c4cdd5;
    --color-primary: #3FB9CB;
    --color-body: #212529;
    --p-border-radius-wide: 3px;
    --p-text-subdued: rgba(109, 113, 117, 1);
    --p-surface: rgba(255, 255, 255, 1);
    --p-card-shadow: 0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
    --step-success: #00D5B1;
}

@media (max-width: 800px) {
	:root {
        --margin-left-menu: 0rem;
        --padding: .5rem;
        --padding-table: .5rem;
	}
}

body{
    margin: 0px;
    overflow: hidden;
    background-color: var(--main-bg-color);
    font-family: -apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif;
    font-size: var(--font-size);
    color: var(--p-text);
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form input.se-input-form._se_image_file{
    display: none;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-btn.se-dialog-files-edge-button.se-file-remove{
    display: none;
}

.content-widget{
    background-color: var(--background-table-color);
}
.alingLeft {
    float: left;
}
.alingRight {
    float: right;
}
.border-0 {
    border: none;
}
.load-img-carriers{
    width: 4rem;
}

.dashboard-video{
    height: 420px;
    position: relative;
    display: flex;
}
.dashboard-video iframe{
    width: 700px;
}
.dashboard-video div{
    margin-left: 2%;
}
@media (max-width:1100px){
    .dashboard-video{
        flex-wrap: wrap;
    }
}

.content-flex-two p{
    flex: 1 0 49%;
}
.content-flex-two p:first-of-type{
    margin-right: 2%;
}

.content-popup{
    text-align: center;
    margin: 2rem;
}
.content-popup div{
    margin: 2rem 0rem;
}
/*LOGIN*/

.login{
    background-image: url(/img/pantalla-triciclo-go.png);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    cursor: pointer;
}
.login button.btn{
    font-size: 2rem;
}
.login img{
    width: 13rem;
    position: absolute;
    bottom: 5px;
    left: 1rem;
    animation: fadeInBack 3s ease-in 7s backwards;
}

.login h2 {
    font-size: 5rem;
    width: 100%;
	color: transparent;
	animation: blurFadeInOut 3s ease-in backwards;
}
.login .success{
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 2rem;
    text-align: center;
    margin: 1rem auto;
    max-width: 62rem;
}
.login .success .title{
    color: green;
    font-size: 2.5rem;
}
.login .success h3{
    margin-bottom: 3rem;
}
@keyframes fadeInBack {
	0% {
		opacity: 0;
		transform: scale(0);
    }
    20% {
		opacity: 0.2;
		transform: scale(0.2);
	}
	40% {
		opacity: 0.4;
		transform: scale(0.4);
    }
    60% {
		opacity: 0.6;
		transform: scale(0.6);
    }
    80% {
		opacity: 0.8;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@-webkit-keyframes blurFadeInOut {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-webkit-transform: scale(1.3);
	}
	20%, 75% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-webkit-transform: scale(1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 50px #fff;
        -webkit-transform: scale(1);
        color:#fff;
	}
}
@-webkit-keyframes blurFadeIn {
	0% {
		opacity: 0;
		text-shadow: 0px 0px 40px #fff;
		-webkit-transform: scale(1.3);
	}
	50% {
		opacity: 0.5;
		text-shadow: 0px 0px 10px #fff;
		-webkit-transform: scale(1.1);
	}
	100% {
		opacity: 1;
		text-shadow: 0px 0px 1px #fff;
		-webkit-transform: scale(1);
	}
}
.login h2.frame-1 {
    animation-delay: 0s;
    color:#fff;
    margin-top: 2rem;
}
.login h2.frame-5 {
	-webkit-animation: none;
	-moz-animation: none;
	-ms-animation: none;
	animation: none;
	color: transparent;
	text-shadow: 0px 0px 1px #cfcfcf;
    font-size: 25px;
}
.login h2.frame-5 span {
	animation: blurFadeIn 2s ease-in 3s backwards;
	color: transparent;
	text-shadow: 0px 0px 1px #fff;
}
.login h2.frame-5 span:nth-child(2) {
	animation-delay: 4s;
}
.login h2.frame-5 span:nth-child(3) {
	animation-delay: 5s;
}
/*Header*/

.header {
    overflow: hidden;
    background-color: var(--color-primary);
    position: fixed;
    top: 0;
    padding: 20px 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    justify-content: space-between;
    z-index: 99;
    width: 100%;
    height: 60px;
    box-shadow: 0 0 3px 3px rgb(0 0 0 / 15%);
}

.header a {
    color: #241F20;
    text-align: center;
    text-decoration: none;
    padding-right: 1rem;
  }
.header a:hover {
    color: #f2f2f2;
    text-decoration: none;
}  
.header  input {
    background-color: #4d5ec1;
    box-shadow: none;
    padding: 10px 5px;
    border-radius: 0.25rem;
    color: #f2f2f2;
    transition: all 0.2s ease-in-out;
    width: 50%;
}
.header input:focus {
    background-color: #fff;
    color: #333;
}
.padding-left-min{
    padding-left: var(--padding);
}
.header .avatar svg{
    border-radius: 50%;
    margin-right: 0.5rem;
}

.logo svg path {
    fill: #241F20;
    }
    
.header .avatar, .m-right {
    margin-right: .5em;
}
    
.header .avatar svg {
    width: 30px;
    background: white;
    height: 30px;
    padding: 7px;
}

.header .btn-dark.btn {
    background: none;
    border: 2px solid #241F20;
    border-radius: 50px;
    color: #241F20;
    font-family: itc-avant-garde-gothic-pro,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 2px;
    padding: 8px 22px;
    line-height: 12px;
    display: inline;
}

/*Contents*/

.Content{
    padding: var(--padding);
    margin: 60px 0px 0px 0px;
    overflow: auto;
    height: 100%;
    position: absolute;
    background-color: var(--main-bg-color);
    width: 100%;
    transition: all 0.5s;
    padding-bottom: 5rem;
}
.Content h1{
    font-size: 2rem;
    line-height: 3.2rem;
}
.imgUpload, .align-left{
    text-align: left;
}

.select-Img .select-image{
    display: flex;
    justify-content: center;
}
.select-Img .select-image img{
    width: 64px;
    margin: auto 2rem;
}
.select-Img .select-image div{
    width: 124px;
    text-align: center;
    cursor: pointer;
}

.img-example, .col-12.img-example{
    display: block;
    max-height: 6rem;
    width: auto;
    margin-bottom: 2rem;
}

.contract-marca .input-group-text, .min-width-12{
    min-width: 12rem;
}

/*Menu*/

.menu{
    background-color: #303336;
    margin: 0;
    padding: 0;
    width: var(--width-menu);
    position: fixed;
    height: 100%;
    overflow: auto;
    top: 60px;
    left: var(--negative-width-menu);
    transition: all 0.5s;
    padding-bottom: 6rem
}
.open-menu .menu{
    left: var(--width-menu-closed);
    z-index: 99;
}

.open-menu .Content{
    margin-left: var(--margin-left-menu);
    width: calc(100% - var(--margin-left-menu));
}

.menu h2{
    margin: 0px;
    padding: 0px 8px;
    background-color: #1d1d1d;
    color:#fff;
    height: 75px;
    line-height: 75px;
}
.menu p.text{
    margin: 0px;
    padding: 8px;
    color:#dddddd;
}

.menu ul{
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.menu ul li a {
    display: block;
    color: #ffffff;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 1rem;
}
.menu ul li a i, .padding-right{
    padding-right: 0.5rem;
}

.menu ul li.active {
    background-color: var(--color-active);
    color: white;
}

.menu ul li a:hover:not(.active) {
    background-color: #555;
    color: white;
}

/*Forms*/

.container-form h1{
    color: #9BC4B6;
    font-size: 2em;
    text-align: center;
    font-family: "Avenir Next",sans-serif;
    font-weight: 700;
    margin: 2rem auto;
}
.container-form h4{
    color: #69727b;
    font-size: 16px;
    font-family: "Avenir Next",sans-serif;
    text-align: center;
    margin: var(--padding) auto;
}
.div-conteiner{
    margin: 0.5rem auto;
}

.container-form label {
    font-size: 14px;
    color: #4f4f4f;
}
.espaciado{
    height: 1rem;
}
textarea.form-control{
    min-height: 100px;
}
.note{
    padding-top: 0px;
    text-align: justify;
}
.mode-edit{
    font-weight: bold;
}
.container-button{
    float: left;
    margin: 0.5rem 0 0.5rem 0;
    padding-left: 15px;
}
.label-without-spacing{
    margin-bottom: 0rem;
}
.label-without-spacing .espaciado{
    height: 0rem;
}
.error{
    color: #ff0033;
    font-weight: 500;
    width: 100%;
    font-size: 0.9rem;
}
.alert-danger, .alert.alert-danger {
    color: #fff;
    background-color: red;
    border-color: red;
}

input.error, select.error, .error input, .error select{
    border-color: #ff0033 ;
}
.error .input-group-text{
    background: #ff0033;
    color: #FFF;
    border-color: #ff0033;
}
.error-tr{
    background: #FFB279;
}
.info{
    width: 15px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    top: -2px;
}

.icon-search-text{
    position: relative;
    margin-top: -2.7rem;
    font-size: 2rem;
    margin-left: 95%;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.icon-search-text.active{
    transform: rotate(180deg);
}

.icon-search-icon{
    position: absolute;
    margin-top: 6px;
    margin-left: 15px;
}
.form-control.active{
    padding-left: 40px;
}


.search-text{
    position: absolute;
    background: #fff;
    border-radius: 0px 0px 5px 5px;
    cursor: pointer;
    margin-top: -5px;
    border: 1px solid rgb(0 0 0 / 11%);
    z-index: 1;
    max-height: 50vh;
    overflow: auto;
    min-width: 59%;
    box-shadow: 0 18px 19px 0 rgb(32 42 53 / 20%), 0 2px 5px 0 rgb(32 42 54 / 8%);
}
.search-text ul{
    list-style: none;
    padding: 0;
    margin-bottom: 0px;
}
.search-text ul li{
    padding: 5px 10px 5px 10px;
}

.search-text ul li.active{
    display: flex;
    justify-content: space-between;
}

.search-text ul li:hover{
    background: #a3a3a3;
}

.tooltip-text{
    position: absolute;
    background: #fff;
    width: 17rem;
    padding: 1rem;
}

.tooltip-component{
    font-size: 0.9rem;
    line-height: 20px;
    box-shadow: 7px 2px 15px 6px rgb(0 0 0 / 40%), 0 2px 12px 0 rgb(0 0 0 / 11%);
    position: absolute;
    z-index: 99;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    max-width: 16rem;
    margin-top: 5px;
    color: #212529;
}
.tooltip-component-bubble{
    display: inline-flex;
}
.tooltip-component-bubble:after {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
    border-width: 0 10px 10px;
    border-color: #ffff transparent;
    margin-left: -18px;
    z-index: 999;
    margin-top: 5px;
}
.per-cent-30{
    width: 30%;
}
.per-cent-50{
    width: 50%;
}
.per-cent-90{
    width: 90%;
}
.table thead th{
    text-align: center;
    font-size: 0.8rem;
}
.tr-active{
    background: #FFFDF2;
}
.tr-shipping{
    background: #F5FFF2;
}
.right{
    float: right;
}
.btn-success.btn{
    background-color: #234E79;
    border-color: #234E79;
}
.btn-success.btn:hover{
    background-color: #9BC4B6;
    border-color: #9BC4B6;
}
.container  hr{
    border-top-color: #9BC4B6;
}
.input-group.mb-3{
    min-width: 100px;
}
.input-multiple-min .input-group-text{
        min-width: 16rem;
}
.label-link label{
    text-decoration: underline;
    font-weight: bold;
}
.content-table-sub-row{
    display: flex;
    justify-content: space-between;
}
/* Print */
.signature{
    width: 100%;
    border-bottom: 1px dotted #ccc;
    height: 40px;
    margin: -24px 0px;
    min-width: 10rem;
}
.iframe-external{
    width: 100%;
    height: 100vh;
}
.iframe-print{
    width: 100%;
    min-height: 80vh;
}
.btn.print{
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
}
.btn.print.last{
    margin-right: 0rem;
}
.print-page{
    margin: 1rem;
}
.print-page .print-logo{
    
}
.print-page .print-logo img{
    height: 150px;
}
.print-page .print-note{
    flex: 1;
}
.print-page .content-loading{
    margin-top: 2rem;
}
.print-page table{
    width: 100%;
    margin-bottom: 0.5rem;
}
.print-page table thead tr th{
    border-top: 2px solid #333;
    border-bottom: 2px solid #aaa;
    padding: 1rem 0rem;
}
.print-page table tbody tr td{
    border-bottom: 2px solid #aaa;
    padding: 0.8rem 0.4rem;
}
.print-page table tbody tr td.max-width-15{
    max-width: 15rem;
}
.print-page table tbody tr:nth-child(odd) td{
    background-color: #ccc;
}
.print-page .header-print, .print-page .table-subtotal, .print-page .print-destination{
    margin-bottom: 3rem;
}
.print-page .header-print{
    margin-top: 2rem;
}
.print-page .print-total{
    border-bottom: 2px solid #ccc;
}
.print-page .subtotal, .print-page .print-total{
    padding: 0.8rem 0rem;
}
.print-page .print-total.total{
    border-bottom: 2px solid #333;
    border-top: 2px solid #333;
    font-weight: bold;
}
/*List_table*/
.content-widget{
    border: .1rem solid var(--p-border-subdued);
    overflow: hidden;
}
.content-widget table:not(.CalendarMonth_table){
    width: calc(100% - 1.6rem);
    margin: var(--padding-table);
}
.content-widget table thead th{
    padding: var(--padding-table) var(--padding-table) var(--padding-table) 0;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
    border: 0;
}
.content-widget table thead th:last-of-type{
    padding-right: 0.5rem;
}
.content-widget table tbody td:not(.CalendarDay){
    text-align: left;
    padding: var(--padding-table) var(--padding-table) var(--padding-table) 0;
    box-shadow: 0 -0.1rem 0 0 #dfe3e8;
}
.content-widget table tbody td:last-of-type:not(.CalendarDay){
    padding-right: 0.5rem;
}
.content-widget table tbody td:not(.CalendarDay).nowrap, .nowrap{
    white-space: nowrap;
}
.content-widget table tbody td:not(.CalendarDay).ellipsis{
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    max-width: 20%;
}
.send{
    background: rgb(246, 243, 195);
    padding: 4px 8px;
    border-radius: 12px;
    font-size: .8rem;
    line-height: 1.2rem;
    font-weight: 500;
    color: rgb(212, 118, 31);
}
.send.ok{
    background-color: var(--send-ok-color);
    color:#FFF;
}
.send.partial{
    background-color: #FFA167; 
    color:#fff;
}
.send i {
    padding-right: 2px;
}

.filter-list{
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: .1rem solid var(--p-border-subdued);
    padding: var(--padding-table);
}
.content-button{
    border-bottom: .1rem solid var(--p-border-subdued);
    padding: var(--padding-table);
}
.content-button .filter-list{
    border-bottom: none;
    padding: 0rem;
}
.content-button button{
    margin-top: 0rem;
}

.select-recharge.select-text{
    width: 100%;
    color: #fff;
    padding: 5px;
    margin-bottom: 1rem;
}
.select-recharge option{
    background: black;
}

.filter-list li{
    display: flex;
    margin: 0;
    padding-right: .8rem;
}

footer{
    margin-bottom: 2rem;
}

.filter-list li a{
    position: relative;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    margin-bottom: -2px;
    outline: none;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 500;
    color: #212b36;
    background-color: initial;
    padding: .4rem .8rem;
    border-radius: 3px;
    border: .1rem solid transparent;
    box-shadow: 0 0 0 0 transparent;
    font-size: 1rem;
    cursor: pointer;
}

.filter-list li .form-control{
    border: none;
    font-weight: 500;
    color: #212b36;
    border-bottom: 1px solid #212b36;
    border-radius: 0px;
    height: 2rem;
    line-height: 1rem;
    position: relative;
    top: 4px;
    padding: .4rem .8rem;
}

.filter-list li a.active, .filter-list li.active .form-control{
    background-color: rgba(92,106,196,.15);
    color: #202e78;
}

.filter-table{
    padding: var(--padding-table);
    padding-bottom: 0px;
}
.filter-table input, .filter-table select, .select-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal;
    padding: .2rem 1rem;
    background: none;
    border: .1rem solid var(--p-border);
    border-radius: var(--p-border-radius-base);
    box-shadow: inset 0 1px 0 0 rgba(99,115,129,.05);
}
.filter-table input, .filter-table .content-input-search{
    position: relative;
    z-index: 20;
    display: block;
    flex: 1 1;
    width: 100%;
    min-width: 0;
    margin: 0;
}
.filter-table .content-input-search span{
    line-height: 1.5rem;
    font-size: 0.8rem;
}
.filter-table select{
    position: absolute;
    margin-top: -42px;
    z-index: 20;
    right: 2rem;
    height: 42px;
    border: none;
    border-left: .1rem solid var(--p-border);
}
.filter-table .filter select{
    position: initial;
    margin-top: 0px;
    width: 100%;
    border: 0.1rem solid var(--p-border);
}
.filter-table select:nth-last-of-type(2){
    right: 12rem;
}
/*Cargando*/
.content-loading{
    background: #d1d1d1;
    width: calc(100% - 3.7rem);
    position: absolute;
    margin-top: -40px;
    border-radius: 5px;
}
.content-loading .loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.content-loading.returns-loading{
    width: calc(100% - 8.7rem);
}

.stage .alert{
    padding: 0.2rem;
    margin: 0px;
    border-radius: 0px 0px 0.25rem 0.25rem;
    font-size: 14px;
    width: 90%;
}

.td-stage .stage .form-control{
    padding: 0px;
    width: 90%;
    margin: 0;
    line-height: 1rem;
    height: 2rem;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0px;
}

.preloaderHtml {
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    border: 5px solid transparent;
    border-top: 5px solid var(--color-primary);
    border-radius: 50%;
    animation-name: girar;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes girar {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.promo{
    width: 100%;
    margin-bottom: 2rem;
}

.promo img{
    width: 100%;
}
.alert .preloaderHtml {
    position: relative;
    margin-right: 1rem;
}

.alert.alert-fixed{
    position: fixed; 
    top: 60px; 
    left: var(--width-menu);
}
#preloader{
    position:relative;
    margin-right: 70px;
}
#preloader span{
    display:block;
    bottom:0px;
    width: 9px;
    height: 5px;
    background:#9b59b6;
    position:absolute;
    animation: preloader 1.5s  infinite ease-in-out;
}
 
#preloader span:nth-child(2){
left:11px;
animation-delay: .2s;
 
}
#preloader span:nth-child(3){
left:22px;
animation-delay: .4s;
}
#preloader span:nth-child(4){
left:33px;
animation-delay: .6s;
}
#preloader span:nth-child(5){
left:44px;
animation-delay: .8s;
}
@keyframes preloader {
    0% {height:5px;transform:translateY(0px);background:#9b59b6;}
    25% {height:30px;transform:translateY(15px);background:#3498db;}
    50% {height:5px;transform:translateY(0px);background:#9b59b6;}
    100% {height:5px;transform:translateY(0px);background:#9b59b6;}
}
/* pickups */
.pickups h4{
    padding: 1rem 1rem 0rem 1rem;
    height: 3rem;
}
/* ORDEN */
.orden .title{
    display: flex;
    align-items: baseline;
    white-space: nowrap;
}

.orden .title .sub-title{
    padding: 0rem var(--padding);
}
.orden .content-widget{
    padding: var(--padding);
    margin-bottom: var(--padding);
}

.orden .address-order{
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1rem -1rem;
    padding: var(--padding);
}
.empaque .content-widget{
    padding: var(--padding);
}
.orden .content-widget:nth-child(1){
    flex: 1;
    margin-right: var(--padding);
}
.orden .content-widget.not-margin-right{
    margin-right: 0rem;
}
.orden .content-loading{
    margin: 0px;
    width: calc(100% - 2rem);
}
.orden .content-loading .loading{
    height: 50px;
}
.orden .content-widget table{
    margin: var(--padding) 0rem;
    width: 100%;
}
.orden .content-widget.client{
    flex: 0 0 35%;
}
.orden .content-widget.client.label{
    flex: 0 0 685px;
}

table tr.active{
    background: var(--color-active);
    color: #fff;
}
/*FILTER*/
.DayPicker_transitionContainer__horizontal{
    min-height: 400px;
}
.filters-content{
    padding-left: var(--padding);
    padding-top: 0.5rem;
}

.filter{
    background: #fff;
    position: absolute;
    min-width: 25rem;
    padding: 1rem;
    top: 0px;
    z-index: 23;
    right: 0px;
    height: 100%;
    box-shadow: 0 31px 41px 0 rgba(32,42,53,.2),0 2px 16px 0 rgba(32,42,54,.08);
    transition: all 0.8s;
}
.no-left, button.btn.no-left{
    padding-left: 0px;
}
.filter .select-text{
    width: 100%;
}
.filter .close-icon{
    position: absolute;
    top: 12px;
    right: 0px;
    margin: 1rem;
    font-size: 1.8rem;
    color: var(--color-body);
}

.filters-apllied{
    background: var(--p-border-subdued);
    padding: 4px 0.7rem;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 1rem;
    display: flex;
    align-items: center;
}
.filters-apllied i.icofont-close-squa#ff0033-alt{
    padding-left: 0.5rem;
}
.filters-apllied i.icofont-calendar{
    padding-right: 0.5rem;
}
button.btn:focus, button.btn.focus{
    box-shadow: none;
}

/*PDF*/
.pdf .pdf-viewer{
    width: 100%;
    height: calc(100vh - 76px);
}
.pdf .content-loading, .about .content-loading, .recharge .content-loading{
    margin-top: 0px;
    width: calc(100% - 2rem);
}

.content-loading.witch-table{
    position: relative;
    width: 100%;
}
.pdf .load{
    max-width: 300px;
    margin-top: 60px;
}

.recharge .content-widget:nth-child(1){
    margin-right: 0rem;
}
/*Paginacion*/
.pagination{
    justify-content: center;
    font-size: 2rem;
    cursor: pointer;
}
.pagination > div:not(.no-Class) {
    background: var(--p-action-secondary,linear-gradient(180deg,#fff,#f9fafb));
    border: .1rem solid var(--p-border);
    border-radius: 3px;
    line-height: 1;
    color: var(--p-text);
    margin: 0rem 1rem 1rem 1rem;
}
.pagination div.disabled {
    background: #f4f6f8;
    color: #919eab;
    cursor: default;
    box-shadow: none;
}
/*About*/

.about .content-widget{
    padding: var(--padding);
    margin-bottom: 1rem;
}
.about .content-loading{
    width: calc(100% - 2rem);
}
.about .content-widget > h1, .about .content-widget > h2{
    padding-left: 1rem;
}

/*preferences*/
.preferences-detail{
    min-width: 350px;
}
.preferences-detail .label,.margin-bottom-1{
    margin-bottom: 1rem;
}
.preferences .space-between .label{
    flex: 0 0 50%;
}
.preferences .space-between .label:first-of-type{
    padding-right: 1rem;
}

/*Empaque */
.input-text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: normal;
    padding: .2rem 1rem;
    background: none;
    border: .1rem solid var(--p-border);
    border-radius: var(--p-border-radius-base);
    box-shadow: inset 0 1px 0 0 rgba(99,115,129,.05);
    min-width: 300px;
}

.imput-align-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

/*Checkbox*/

.form-check-conteiner {
    display: block;
    position: relative;
    padding-left: 25px;    
    height: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0rem;
  }
  
  /* Hide the browser's default checkbox */
  .form-check-conteiner input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .form-check-span {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border: 1px solid #aaa;
    border-radius: 3px;
    margin-left: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .form-check-conteiner:hover input ~ .form-check-span {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .form-check-conteiner input:checked ~ .form-check-span {
    background-color: var(--color-primary);
  }

  .form-check-conteiner input:disabled ~ .form-check-span {
    background-color: #aaa;
    border-color: #333;
    cursor: not-allowed;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .form-check-span:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .form-check-conteiner input:checked ~ .form-check-span:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .form-check-conteiner .form-check-span:after {
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

/*Dashboard*/
.card  {
    margin: 15px!important;
    border-radius: 5px !important; /* 5px rounded corners */
    padding: 2px 2px;
    height: 120px;
    width: 180px;
    background-color: #63a1f1!important;
    border-color: #8a94a0  !important;
    border-style: double;
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.411);
    text-align: center;
  }
.card-green {
    background-color: green!important;
}
.card-blue {
    background-color: #238ea1c5!important;
}
.card-lightgreen {
    background-color: #31d683b0!important;
}
.card-pink {
    background-color: #f146469c!important;
}
.card-container{
    
    display: inline-flex;
    max-width: 100%;
    padding-left: 15%;
    padding-right: 15%;

    justify-content: space-between;
}
.dataselector{
    padding-right: 10px;
}
.midline {
    padding-top : 28px;
}
/*LOCAL ORDER*/
.local-orders .list-orders .item-order{
    display: flex;
    align-items: center;
}
.local-orders .list-orders .fix.item-order {
    display: flex;
    align-items: center;
    background-color: rgb(236, 236, 236);
}
.local-orders .list-orders .items .items-dd::before {
    content: "";
    position: absolute;
    top: 12rem;
    bottom: 13.5rem;
    left: 24px;
    width: 1px;
    border-left: 1px dotted rgb(241, 102, 34);
    z-index: 0;
}

.local-orders .list-orders .items .items-dd .item-dd > div:hover{
    background: #E4FFDD!important;
}
.local-orders .list-orders .items select{
    margin: 1rem;
    width: calc(100% - 1rem);

}
.local-orders .list-orders .item-order .number{
    margin: 1rem;
    background: rgb(241, 102, 34);
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 2px;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.714em;
    z-index: 2;
}
.local-orders .list-orders .item-order .address{
    border-bottom: 1px solid rgb(180, 180, 180);
    padding: 0.8rem 0rem;
    width: 100%;
}
.local-orders .list-orders .item-order.shop{
    background: var(--color-primary);
    color: #fff;
}
.local-orders .cost{
    padding: 1rem;
    text-align: right;
    background: #6371c7;
    color: #fff;
    margin-left: 1rem;
    margin-top : 1rem;
}
.local-orders .cost span{
    min-width: 4rem;
    display: inline-block;
} 
.icon-delete{
    position: absolute;
    right: 1rem;
    cursor: pointer;
}

/*Recoleccion*/
.insured > div{
    padding-left: 1rem;
}
.content-flex.flex-center.content-pickup{
    margin-bottom: 0px;
}
.content-pickup .insured > div > div{
    display: flex;
}
.content-pickup .form-check{
    padding: 0px;
}
.content-pickup .form-check:nth-child(2){
    padding-left: 2rem;
}
.content-pickup-datePicker.input-group{
    width: 11.4rem;
}
div.input-ghost{
    min-height: 50px;
}
.img-prod{
    width: 5rem;
}
/* Mapa*/
.container-map > div:nth-child(1){
    flex: 1 0 40%;
    position: relative;
    padding-right: 1rem;

}
.container-map > div:nth-child(2){
    flex: 1 0 60%;
    position: relative;
    height: calc(100vh - 150px);
    min-width: 60%;
}
.container-map > div:nth-child(2) .container-loading{
    height: calc(100vh - 150px);
    align-items: center;
    display: flex;
    justify-content: center;
    background: #CCC;
}
/*STAGES*/

.email-template{
    border: .1rem solid var(--p-border-subdued);
    padding: 1rem;
    background-color: var(--background-table-color);
    margin-right: 1rem;
    flex: 1 1 auto;
}

.email-help{
    border: .1rem solid var(--p-border-subdued);
    padding: 1rem;
    flex: 0 0 15rem;
    background-color: var(--background-table-color);
}
.email-help .shortcode p{
    color: var(--background-table-color);
    background: var(--color-primary);
    padding: 0.4rem 0.5rem;
    cursor: pointer;
}
.help-copy{
    position: absolute;
    font-size: 0.715rem;
    background: black;
    color: #fff;
    padding: 0.2rem 0.5rem;
    margin-top: -1rem;
}
.definition-simulate-email{
    width: 25%;
}

.simulate-email{
    width: 75%;
    margin: 1rem;
    border: 1px solid #ccc;
    padding: 1rem;
    background: #ccc;
}

.text-area textarea{
    height: 300px;
}

.select-icon{
    width: 55px;
    border: 3px solid #cfcfcf;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 5px;
}
.select-icon.active{
    border-color: #5563c1;
}
/*SWITCH*/

.switchBtn {
    position: relative;
    display: inline-block;
    min-width: 110px;
    height: 34px;
    margin-bottom: 0px;
}

.switchBtn input {display:none;}
.switchBtn .slide {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 4px 8px;
    color: #fff;
}
.switchBtn .slide:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 78px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
.switchBtn input:checked + .slide {
    background-color: #8CE196;
    padding-left: 40px;
}
.switchBtn input + .slide .active{
    display: none;
} 
.switchBtn input + .slide .disabled{
    display: block;
    font-size: 0.9rem;
    line-height: 30px;
} 
.switchBtn input:checked + .slide .disabled{
    display: none;
}
.switchBtn input:checked + .slide .active{
    display: block;
}
.switchBtn input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
}
.switchBtn input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -20px;
}

.switchBtn .slide.round {
    border-radius: 34px;
}
.switchBtn .slide.round:before {
    border-radius: 50%;
}

.switchBtn.orderSwitchBtn{
    width: 130px;
}
.switchBtn-text{
    line-height: 34px;
    font-weight: bold;
}
p.no-margin, .no-margin{
    margin: 0px;
}
.switchBtn.orderSwitchBtn .slide:before{
    left: 100px;
}

.switchBtn.large{
    width: 11rem;
}

.switchBtn.large .slide:before{
    left: 145px;
}

.img-returns{
    width: 8rem;
    max-height: 5rem;
}

.img-return{
    max-width: 100%;
}

.img-return-shopify{
    max-width: 100%;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 3px;
    margin-bottom: 1rem;
}

pre.pre-shopify{
    width: 100%;
    white-space: pre-wrap;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 3px;
}

.returns-shopify h3{
    margin-top: 2rem;
}

/*Selector de imagen*/
.icon-td img{
    width: 2rem;
}
.select-image label{
    margin: 0px;
}
.select-image .select-image-icon img{
    width: 2rem;
    margin: 0rem 1rem;
}
.select-image .select-image-list{
    position: absolute;
    display: flex;
    position: absolute;
    display: flex;
    width: 40%;
    flex-wrap: wrap;
    z-index: 9;
    background: #fff;
    padding: 1rem;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.select-image .select-image-list img{
    margin: 0.5rem;
    width: 2rem;
}

.select-image .select-image-list .active{
    background-color: #8CE196;
}

/*Tabs*/

.tabs{
    position: relative;
    z-index: 1;
    top: 1px;
}

.tab {
	display: block;
    padding: 1rem 2rem;
    border: 1px solid #ccc;
    border-bottom: none;
    cursor: pointer;
    background: #90CAF9;
    transition: background ease 0.2s;
    color: #666;
}

.tab.pred0, .tab.manual1{
    background: #fff;
    font-weight: bold;
    color: #333;
}
.tabs-content{
    border: 1px solid #ccc;
    padding: 1rem;
}

/*Upload Iamgen*/
.content-upload-image .content-loading{
    position: relative;
    min-height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-upload-image img:nth-child(1), .content-upload-image img:nth-child(3){
    flex: 1 0 40%;
    max-width: 40%;
    border-radius: 5px;
    border: 5px solid green;
    padding: 0.5rem;
    max-height: 15rem;
}
.content-upload-image img:nth-child(2){
    flex: 1 0 20%;
    max-width: 50px;
    margin: 0px 10px;
}
.button-center{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
.content-upload-image img:nth-child(1){
    border-color: #ff0033;
}
.content-upload-image img.no-border{
    border: none;
    margin-right: 1rem;
}

/*STEPS*/
.steps {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding-left: 0px;
}
.steps.has-content-cente#ff0033 {
    text-align: center;
}

.steps.is-medium {
    font-size: 1rem;
}

.steps .steps-segment {
    position: relative;
    flex: 1;
}

.steps .steps-segment:not(:last-child) {
    flex-basis: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
}

.steps .steps-marker {
    background-color: #23d160;
    color: #fff;
}

.steps .steps-marker i{
    font-size: 1rem;
}

.steps.is-medium.has-content-cente#ff0033 .steps-marker {
    position: absolute;
    left: calc(50% - 1.25rem);
}

.steps.is-medium.has-content-cente#ff0033 .steps-content {
    margin-top: 3.5rem;
    margin-left: .5em;
    margin-right: .5em;
    padding-top: .2em;
}
.steps.is-medium .steps-content{
    padding-right: 1rem;
}
.steps.is-medium .steps-content.top{
    margin-top: 5px;
    margin-bottom: 0.5rem;
} 

.steps.is-medium .steps-content.top .is-size-4{
    margin-bottom: 0px;
}

.heading {
    display: block;
    font-size: 11px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.steps.is-medium .steps-segment:not(:last-child):after {
    height: .4em;
    left: 2.26rem;
    right: -.24rem;
    top: calc(3.6rem - (.2em));
}

.steps.is-medium.has-content-cente#ff0033 .steps-segment:not(:last-child):after {
    left: 50%;
    right: -50%;
}

.steps .steps-segment:not(:last-child):after {
    content: " ";
    display: block;
    position: absolute;
}

.steps-segment:after {
    background-color: var(--step-success);
}

.steps.is-medium.has-content-cente#ff0033 .steps-marker {
    position: absolute;
    left: calc(50% - 1.25rem);
}

.steps .steps-marker {
    background-color: var(--step-success);
    color: #fff;
}

.steps.is-medium .steps-marker {
    height: 2.5rem;
    width: 2.5rem;
    overflow: hidden;
}

.steps .steps-marker {
    align-items: center;
    display: flex;
    border-radius: 50%;
    font-weight: 700;
    justify-content: center;
    position: relative;
    z-index: 5;
}

.steps-segment.is-active ~ .steps-segment .steps-marker.is-hollow {
    border-color: #dbdbdb;
}

.steps.is-medium:not(.is-vertical):not(.is-thin).has-content-cente#ff0033 .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.55rem);
    right: calc(-50% + 1.55rem);
}

.steps-segment.is-active ~ .steps-segment:after, .steps-segment.is-active:after {
    background-color: #dbdbdb;
    margin-left: 0.4rem;
    border-radius: 3px;
    margin-right: 0.4rem;
}

.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow) {
    background-color: #dbdbdb;
    color: rgba(0,0,0,0.7);
}

.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment.is-current .steps-marker:not(.is-hollow){
    background: #FFE13E;
}

.steps-segment.is-current{
    font-weight: bold;
}

.steps.is-medium:not(.is-vertical):not(.is-thin).has-content-cente#ff0033 .steps-segment.has-gaps:not(:last-child):after {
    left: calc(50% + 1.55rem);
    right: calc(-50% + 1.55rem);
}

.steps:not(.is-vertical).is-narrow.has-content-cente#ff0033 .steps-segment:last-child {
    flex-grow: 0;
    min-width: 10em;
}
/* Dashboard */
.Polaris-Card_yis1o:not(:first-child) {
    margin-left: 1rem;
}
.Polaris-Card_yis1o {
    border-radius: var(--p-border-radius-wide);
}
._3r3-r {
    padding: .8rem;
}
._1VUFI {
    color: var(--p-text-subdued);
    margin-bottom: .8rem;
}
._3WdC8 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 3rem;
}
.Polaris-Stack--noWrap_vecks {
    flex-wrap: nowrap;
}
.Polaris-Stack--spacingExtraTight_gv6hw {
    margin-top: -.4rem;
    margin-left: -.4rem;
}
.Polaris-Stack--distributionEqualSpacing_x9cqm {
    justify-content: space-between;
}
.Polaris-Stack--alignmentBaseline_aupj5 {
    align-items: baseline;
}
.Polaris-Stack__Item_yiyol {
    flex: 0 0 auto;
    min-width: 0;
}
.Polaris-Stack--spacingExtraTight_gv6hw>.Polaris-Stack__Item_yiyol {
    margin-top: .4rem;
    margin-left: .4rem;
    max-width: 100%;
}
._3vPiE {
    font-size: 1.6rem;
    line-height: 0.8rem;
}
.Polaris-Subheading_syouu{
    font-size: 1.5rem;
    margin-bottom: 0rem;
}
._3cnsH, ._3vPiE {
    color: #212b36;
    font-weight: 600;
}
.Polaris-Card_yis1o {
    background-color: var(--p-surface);
    box-shadow: var(--p-card-shadow);
    outline: .1rem solid transparent;
}
.Polaris-TextStyle--variationSubdued_1segu {
    color: var(--p-text-subdued);
}
.Polaris-Card_yis1o{
    flex: 1;
    margin-bottom: 1rem;
}

/*STAGES ICON*/

.items-icon{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.items-icon img{
    width: 55px;
}
.items-icon .item-order{
    text-align: center;
}

/*Uso libre*/
.margin-1-0{
    margin: 1rem 0rem;
}
.chart-donut .apexcharts-series path:nth-child(1){
    fill: #68A15C;
}
.chart-donut .apexcharts-series path:nth-child(2){
    fill: #88CC78;
}
.chart-donut .apexcharts-series path:nth-child(3){
    fill: #97DE87;
}
.chart-donut .apexcharts-series path:nth-child(4){
    fill: #B4F2A6;
}

.apexcharts-series path{
    fill: #7BBE6C;
}
.state-shipping i{
    font-size: 2rem;
}
.state-shipping > div{
    display: flex;
    align-items: center;
}
.state-shipping > div > span{
    padding-left: 0.3rem;
}
.content-alert{
    position: absolute;
    z-index: 1;
    left: 0rem;
    width: 100%;
}
.shippings .alert{
    margin: 1rem;
}
.max-width-normal{
    max-width: 22rem;
}
.container-multi-footer.content-flex.flex-end{
    align-items: center;
}
.container-multi-footer .content-flex{
    align-items: center;
    margin-right: 3rem;
}
.container-multi-footer .content-flex > div{
    margin-left: 1rem;
} 
.container-multi-footer .btn {
    margin-top: 0px;
}
.btn.btn-info:disabled{
    background: grey;
}
.alert.alert-warning{
    font-size: 0.8rem;
}
.space{
    padding: var(--padding);
}
.logo-carrier{
    width: 31px;
}
.content-input{
    display: flex;
}
.content-input .input-group-text{
    padding: 0rem 0.5rem;
}
.content-input .input-group-prepend .input-group-text{
    border-radius: 3px 0px 0px 3px;
    min-width: 12rem;
}
.content-input .input-group-append .input-group-text{
    border-radius: 0px 3px 3px 0px;
}
.preferences-install-help img{
    max-width: 100%;
    margin: 0.5rem 0rem 1rem 0rem;
}
.form-group .datePicker > div{
    width: 100%;
}
.form-group .datePicker .icofont-ui-calendar,.form-group .datePicker .icofont-clock-time{
    position: absolute;
    left: 0px;
    font-size: 1.5rem;
    padding: 6px 2px 6px 6px;
    z-index: 3;
}
.form-group .datePicker input{
    padding-left: 35px;
}

.width-20{
    width: 20%;
}

.width-15{
    width: 15%;
}

.readonly .input-group-text{
    background: transparent;
    border: none;
    padding: 0px;
}

.readonly .text{
    border: none;
    padding-left: 0px;
    font-weight: 700;
}
.address-order .readonly .text{
    font-weight: 400;
}
.label-dhl{
    max-width: 650px;
}
.label-dhl-text{
    position: absolute;
    margin-top: 52px;
    font-size: 0.8rem;
    margin-left: 70px;
    font-weight: 700;
}
.label-dhl-text div{line-height: 0.8rem;}
.label{
    margin-bottom: 1rem;
}
.label.label-check{
    display: flex;
    align-items: center;
}
.label.label-check .switchBtn{
    margin-left: 1rem;
    margin-right: 2rem;
}
.m-left{
    margin-left: 2rem;
}
.no-data{
    margin: 2rem 0rem;
}
.no-data i{
    font-size: 2rem;
    margin-bottom: 1rem;

}
.flex-two-element div{
    flex: 1 1 50%;
}
table tbody tr:hover{
    background-color: #e1e1e1;
}
table tbody tr.no-background:hover{
    background-color: transparent;
}
.shopify{
    color: #95BF47;
}
.destination{
    font-size: 0.8rem;
    font-weight: 100;
    margin-left: 1rem;
}
.destination.carrier{
    margin-left: 37px;
}
.without-left{
    margin-left: 0rem;
}
.destination.orden-info{
    margin-left: 1.4rem;
}
.center, .content-widget table thead th.center, .content-widget table tbody td.center{
    text-align: center;
}
.text-right{
    text-align: right;
}

.total{
    max-width: 100%;
}
.click{
    cursor: pointer;
}
.big-font-size{
    font-size: 1.5rem;
}
.content-flex{
    display: flex;
}
.content-flex.space-between{
    justify-content: space-between;
    align-items: flex-end;
}
.content-flex.space-between-start{
    justify-content: space-between;
    align-items: flex-start;
}
.content-flex.space-between-initial{
    justify-content: space-between;
    align-items: initial;
}

.content-flex.flex-end{
    justify-content: flex-end;
    align-items: flex-end;
}
.content-flex.flex-center{
    justify-content: center;
    align-items: center;
}
.content-flex.flex-center-left{
    justify-content: start;
    align-items: center;
}
.insu#ff0033 div div{
    display: flex;
}
.insu#ff0033 div div .form-check{
    margin: 0rem 0.5rem;
}
.content-flex.space-between{
    justify-content: space-between;
    align-items: center;
}

.content-input-filter{
    width: 18rem;
    margin-left: 1rem;
}
.content-select-filter{
    width: 20rem;
    margin-left: 1rem;
}

.content-flex.flex-center-left.form .form-control{
    margin-left: 1rem;
}
.content-package {
    background-image: url(/img/caja_personalizado.png);
    background-repeat: no-repeat;
    background-position: center;
    min-height: 245px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-size: 300px;
}
p.specification, div.specification{
    font-weight: bold;
    display: flex;
    align-items: center;
}
p.specification span, div.specification sapn{
    font-weight: 100;
}
p.specification input, div.specification input {
    margin: 0rem 0.3rem;
}

.content-package p:nth-child(1), .content-package div:nth-child(1){
    margin-right: -435px;
}

.cont-width{
    position: relative;
    margin-top: -45px;

}

.cont-width p:nth-child(1){
    margin-left: 4rem;
    margin-top: -1rem;
}

.cont-width div:nth-child(1){
    margin-left: 5rem;
    margin-top: -1rem;
    margin-right: 2rem;
}

.cont-width p:nth-child(2){
    padding-left: 6rem;
    margin-top: -1rem;
}
.cont-weight{
    margin-left: 6rem;
}

.cont-width div:nth-child(2){
    padding-left: 2rem;
    margin-top: -1rem;
}

.content-package p input, .cont-width p input, .cont-weight p input, .content-package div input, .cont-width div input, .cont-weight div input{
    width: 3.5rem;
}
.spacing-big{
    padding: 3rem;
}
.spacing-medium{
    padding: 1.5rem;
}
.spacing-small{
    padding: 1rem;
}
.spacing-small-top{
    padding-top: 1rem;
}
.spacing-small-left{
    padding: 0rem 0rem 0rem 1rem;
}
.height-70{
    height: 70px;
}
.client p, .client span.text{
    font-size: 0.9rem;
    margin-bottom: 0rem;
    line-height: 1.5rem;
}
.client .label .false.readonly{
    margin-bottom: -0.5rem;
}
.direction-selected button{
    margin-left: 1em;
}
button.btn{  
    padding: .2rem 1rem;
    white-space: nowrap;
}
.btn.triciclo{
    background: -webkit-gradient(linear, left top, left bottom, from(#6371c7), to(#5563c1));
    background: linear-gradient(to bottom, #6371c7, #5563c1);
    border-color: #3f4eae;
    -webkit-box-shadow: inset 0 1px 0 0 #6f7bcb;
    box-shadow: inset 0 1px 0 0 #6f7bcb;
    color:#FFFFFF;
}
.link-small {
    padding: 0rem 0rem!important;
    margin: 0px!important;
}
.btn-icon{
    color:#007bff;
    
}
.btn-icon:hover{
    color:#0056b3;
    
}
.top-middle{
    margin-top: 0.5rem;
}
.bottom-middle{
    margin-bottom: 0.5rem;
}
.right{
    float: right;
}
.error-server .content-widget{
    text-align: center;
    padding: 1rem;
}


._21Z9T {
    display: inline-flex;
    align-items: center;
    padding: .1rem .5rem;
    background-color: #dfe3e8;
    border-radius: 2rem;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #454f5b;
}

.-EFlq {
    height: 0.7rem;
    width: 0.7rem;
    margin: 0 .2rem 0 -.2rem;
    border: .2rem solid currentColor;
    border-radius: 50%;
    flex-shrink: 0;
}
._1EPFc .-EFlq {
    background: currentColor;
}
._33uWB .-EFlq {
    background: transparent;
    color:#c05717;
}
._21Z9T .-EFlq {
    color: #919eab;
}
._3o7e3 {
    background-color: #ffc58b;
    color: #594430;
}
._3o7e3 .-EFlq {
    color: #c05717;
}

.input-form{
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    vertical-align: top;
    height: 28px;
    font-size: 14px;
    display: inline-block;
    color: rgb(34, 34, 34);
    padding: 1rem 0.5rem;
    margin: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(211, 219, 226);
    border-image: initial;
    border-radius: 3px;
}

input.input-form[type=color]{
    padding: 0rem 0.5rem;
}
.scroll-table.scroll-table-min{
    overflow-x: auto;
}
.scroll-table.scroll-table-min table{
    min-width: 600px;
    margin: 0rem var(--padding);
}
.scroll-table.scroll-table-min table tbody tr td:first-of-type, .scroll-table.scroll-table-min table thead tr th:first-of-type{
    background: #fff;
    height: 100%;
    visibility: visible;
    position: sticky;
    left: 0rem;
    padding-left: var(--padding);
}
.scroll-table.scroll-table-min.active table tbody tr td:first-of-type, .scroll-table.scroll-table-min.active table thead tr th:first-of-type{
    box-shadow: 0.1rem -0.1rem 0 0 #dfe3e8;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}


@import url("https://p.typekit.net/p.css?s=1&k=gxa7chf&ht=tk&f=39347.39348&a=8056379&app=typekit&e=css");

@font-face {
    font-family:"itc-avant-garde-gothic-pro";
    src:url("https://use.typekit.net/af/578a9d/00000000000000007735ba14/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/578a9d/00000000000000007735ba14/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/578a9d/00000000000000007735ba14/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
    font-family:"itc-avant-garde-gothic-pro";
    src:url("https://use.typekit.net/af/b89a53/00000000000000007735ba1d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/b89a53/00000000000000007735ba1d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/b89a53/00000000000000007735ba1d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;
}

.tk-itc-avant-garde-gothic-pro { font-family: "itc-avant-garde-gothic-pro",sans-serif; }

.menu {
    background: #2f2f2f;
}
    
.resumen-saldo {    
    text-align: center;
    color: white;
    padding: 25px 12px;
    background: black;
}
    
.saldo-title {
    background: none;
    font-size: 15px;
    font-weight: bold;
}
    
.saldo-number {    
    background: none;
    padding: 0;
    margin: 0;
    line-height: normal;
    height: auto;
    font-weight: 300;
    font-family: 'itc-avant-garde-gothic-pro';
    font-size: 30px;
    letter-spacing: 1px;
    margin-bottom: 15px;
}
    
a.recarga-btn {    
    background: white; 
    border-radius: 50px; 
    padding: 12px 24px;
    color: black;
    font-family: 'itc-avant-garde-gothic-pro';
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 12px;
}
    
.menu ul li.active {    
    background: #505050;
}
    
.menu ul li a {
    padding: 12px 20px;
    font-family: 'itc-avant-garde-gothic-pro';
    font-weight: normal;
    font-size: 15px;
    letter-spacing: .02em;
}
    
.menu li svg {
    fill: white;
    width: 18px;
    height: auto;
    margin-bottom: 4px;
    max-height: 14px;
    margin-right: 8px;
}

div#content {
    padding: 2rem;
}
    
.Polaris-Card_yis1o {
    margin-bottom: 2rem;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 15%);
    border-radius: 0;
    outline: none;
}
    
.Polaris-Card_yis1o:not(:first-child) {
    margin-left: 2rem;
}
    
._3r3-r {
    padding: 2rem;
    text-align: center;
    font-family: 'itc-avant-garde-gothic-pro';
}
    
h3.Polaris-Subheading_syouu {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: 1px;
}
    
.about .content-widget {
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 15%);
    border-radius: 0;
    outline: none;
}
    
.about .content-widget>h1, .about .content-widget>h2 {
    font-family: 'itc-avant-garde-gothic-pro';
    font-weight: bold;
    text-transform: uppercase;
    font-size: 28px;
}
    
button.btn.triciclo.btn-link {
    background: black;
    border: none;
    border-radius: 50px;
    padding: 10px 24px;
    font-family: 'itc-avant-garde-gothic-pro';
    font-size: 14px;
    color: #fff;
}
    
.filters-apllied {
    border-radius: 50px;
    padding: 10px 24px;
    border: none;
    font-family: 'itc-avant-garde-gothic-pro';
    font-size: 14px;
    white-space: nowrap;
}
    
.content-flex.flex-center {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}
    
.apexcharts-text tspan {
    font-family: 'itc-avant-garde-gothic-pro';
}
    
button.btn.triciclo.right {
    background: none;
    color: black;
    font-family: 'itc-avant-garde-gothic-pro';
    font-size: 14px;
    border: 1px solid black;
    border-radius: 50px;
    box-shadow: none;
    padding: 10px 24px;
}


.ordens h1 {
    font-family: itc-avant-garde-gothic-pro;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 28px;
}

a.btn.triciclo.top-middle.right {
    background: black;
    text-transform: none;
    border: none;
    border-radius: 50px;
    padding: 10px 24px;
    font-family: itc-avant-garde-gothic-pro;
    font-size: 14px;
    color: white;
}

.content-widget {
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 15%);
    border-radius: 0;
    outline: none;
    font-family: 'itc-avant-garde-gothic-pro';
}

.filter-list li.active .form-control, .filter-list li a.active {
    background-color: #dfe3e8;
    color: black;
    border-radius: 0;
}

.filter-list li .form-control {
    border: 1px solid #ccc;
    position: inherit;
    height: 40px;
}

.btn.triciclo {
    border: none;
    border-radius: 50px;
    padding: 10px 24px;
    font-family: itc-avant-garde-gothic-pro;
    font-size: 14px;
    background: #dfe3e7;
    box-shadow: none;
    color: black;
}

.filter-table {
    display: flex;
    flex-flow: nowrap;
}

.form-check-conteiner input:disabled~.form-check-span {
    border: 1px solid #ccc;
    border-radius: 0;
    background: #ccc;
}

.form-check-span {
    border: 1px solid #ccc;
    border-radius: 0;
    background: white;
}

i.icofont-grocery.shopify {
    display: none;
}

div#scroll-table a {
    color: #3fbaca;
    font-weight: 600;
}

.destination.orden-info {
    margin: 0;
    line-height: 24px;
}

._21Z9T {
    background: #cfecc8;
    color: #68A15C;
}

._21Z9T .-EFlq {
    color: #6ca45d;
}

div#scroll-table tr:hover {
    background: #ececec;
}

span.send.ok {
    background-color: #90dce6;
    color: #237782;
}

.td-stage .stage .form-control {
    background: #dfe3e7;
    border: none;
    padding: 5px 10px;
    height: 40px;
    font-size: 15px;
}

a.ver-shopify-btn {
    background: none;
    border: 1px solid;
    border-radius: 50px;
    color: black !important;
    font-size: 12px;
    font-weight: normal !important;
    padding: 6px 12px;
}

.content-widget table:not(.CalendarMonth_table) tbody td:not(.CalendarDay) {
    font-size: 15px;
    width: 16%;
}

.content-widget table:not(.CalendarMonth_table) tbody td:first-of-type:not(.CalendarDay) {
    width: 4%;
}

.CalendarMonthGrid__horizontal{
    width: auto!important;
}

@media only screen and (max-width: 768px) {
    /* For desktop: */
    .col-1, .col-2, .col-3, .col-6, .col-8 {
        width: 100%!important;
        max-width: 100%!important;
        flex: 0 0 100%!important;
    }
    .scroll-table{
        overflow-x: auto;
    }
    .scroll-table table{
        min-width: 600px;
        margin: 0rem var(--padding);
    }
    .scroll-table table tbody tr td:first-of-type, .scroll-table table thead tr th:first-of-type{
        background: #fff;
        height: 100%;
        visibility: visible;
        position: sticky;
        left: 0rem;
        padding-left: var(--padding);
    }
    .scroll-table.active table tbody tr td:first-of-type, .scroll-table.active table thead tr th:first-of-type{
        box-shadow: 0.1rem -0.1rem 0 0 #dfe3e8;
    }
    .content-widget table{
        margin: var(--padding-table) 0rem;
    }
    .filter-table select{
        right: 1rem;
    }
    .flex-two-element div{
        flex: 1 1 100%;
    }
    .pdf .content-loading, .about .content-loading{
        width: calc(100% - 1rem);
    }
    .card-container{
        padding-left: 15%;
        padding-right: 15%;

        flex-wrap: wrap;
    }
  }
@media only screen and (max-width: 680px) {
    .content-flex{
        flex-wrap: wrap;
    }
    .content-flex .content-widget{
        flex: 1 1 100%;
        margin-bottom: 2rem;

    }
    .orden .content-widget:nth-child(1){
        margin-right: 0rem;
    }
    .card-container{
        padding-left: 15%;
        max-width: 100%;
        flex-wrap: wrap;
    }
}  
  @media (min-width:576px){
    .container,.container-sm{max-width: 740px!important;}
    .card-container{
  
        flex-wrap: wrap;
    }
}
@media (min-width: 768px){
    .container, .container-md, .container-sm {
        max-width: 1200px!important;
    }
    .card-container{
        padding-left: 15%;
        padding-right: 15%;

        flex-wrap: wrap;
    }
}
@media (min-width: 992px){
    .container, .container-lg, .container-md, .container-sm {
        max-width: 1260px!important;
    }
    .card-container{
        padding-left: 15%;
        padding-right: 15%;

        flex-wrap: wrap;
    }
}
@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 2800px!important;
    }
    .card-container{
        padding-left: 15%;
        padding-right: 15%;

        flex-wrap: wrap;
        flex-direction: row;
    }
}

@media print {
    .menu, .header{display: none}
    .page-break{
        page-break-before: always
    }
    .open-menu .Content{
        margin-left: 0px;
        width: 100%;
    }
    .Content{
        position: initial;
    }
    table{
        width: 100%;
    }
}



